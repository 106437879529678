import React, { useState, useTransition } from "react";
import { CognitoUser, AuthenticationDetails, CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId: 'us-east-2_pNX7BWREV',
  ClientId: '5elea9etkq1o33hbsj63nquctj' // App client id from the user pool
};

const userPool = new CognitoUserPool(poolData);

const ProtectedRoute = ({ children }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState(""); // New state for new password
  const [authenticated, setAuthenticated] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false); // New state to track if a new password is required
  const [isPending, startTransition] = useTransition({ timeoutMs: 3000 });
  const [cognitoUser, setCognitoUser] = useState(null); // Add this line


  const handleLogin = (e) => {
    e.preventDefault();
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });
  
    const user = new CognitoUser({ // Change the variable name here
      Username: username,
      Pool: userPool,
    });

    user.authenticateUser(authenticationDetails, { // And here
        onSuccess: (session) => {
        startTransition(() => {
          setAuthenticated(true);
        });
      },
      onFailure: (err) => {
        console.error(err);
        alert("Incorrect username or password!");
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        console.log('password change required');
        setNewPasswordRequired(true);
        setCognitoUser(user); // Store the CognitoUser object
      }
    });
  };

  const handleNewPassword = (e) => {
    e.preventDefault();
    if (cognitoUser) {
      cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
        onSuccess: (session) => {
          startTransition(() => {
            setAuthenticated(true);
          });
        },
        onFailure: (err) => {
          console.error(err);
          alert("Failed to set new password!");
        }
      });
    } else {
      console.error('CognitoUser object is not available.');
    }
  };
  

  // Optionally, you can add a loading indicator if isPending is true
  if (isPending) {
    return <div>Loading...</div>;
  }

  if (authenticated) {
    return children;
  }

  if (newPasswordRequired) {
    return (
      <div className="login-container"> {/* Change the class name to "login-container" */}
        <form className="login-form" onSubmit={handleNewPassword}> {/* Change the class name to "login-form" */}
          <h1>Change Password</h1>
          <input
            type="password"
            placeholder="New Password"
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <button type="submit">Change Password</button>
        </form>
      </div>
    );
  }

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <h1>User Login</h1>
        <input
          type="text"
          placeholder="Username"
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default ProtectedRoute;
