import React, { useCallback, lazy, useState, useEffect } from "react";
import "./globals.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ProtectedRoute from "./ProtectedRoute.js";

// import PortraitOnly from "./PortraitOnly";

//custom components
import MyHeader from "../src/pages/home/MyHeader";

const Home = lazy(() => import("./pages/Home"));
const WordlePage = lazy(() => import("./pages/WordlePage"));
// const WeatherPage = lazy(() => import("./pages/WeatherPage"));
const SudokuPage = lazy(() => import("./pages/SudokuPage"));
const RealestatePage = lazy(() => import("./pages/RealestatePage"));
const VideoPage = lazy(() => import("./pages/VideoPage"));
const DashboardPage = lazy(() => import("./pages/DashboardPage"));
const PokemonPage = lazy(() => import("./pages/PokemonPage"));

function applyCustomStyles(shouldApply) {
  const gridElements = document.querySelectorAll(".sudoku");
  const middleCElements = document.querySelectorAll(".middle-c");

  if (shouldApply) {
    // gridElements.forEach((element) => {
    //   element.style.filter = "blur(10px)";
    // });
    middleCElements.forEach((element) => {
      element.style.height = "100vh";
    });
  } else {
    gridElements.forEach((element) => {
      element.style.filter = "";
    });
    middleCElements.forEach((element) => {
      element.style.height = "";
    });
  }
}

function useUpdateBodyBackground(backgroundColor) {
  const location = useLocation();
  const isSudokuRoute = location.pathname === "/sudoku";
  const isPokemonRoute = location.pathname === "/pokemon";
  const isMaxWidth812px = useMediaQuery({ query: "(max-width: 812px)" });
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });

  useEffect(() => {
    const shouldApplyStyles =
      (isSudokuRoute && isMaxWidth812px && isLandscape) ||
      (isPokemonRoute && isMaxWidth812px && isLandscape);
    if (shouldApplyStyles) {
      document.body.style.backgroundColor = "rgb(51, 51, 51)";
    } else {
      document.body.style.backgroundColor = backgroundColor;
    }
    applyCustomStyles(shouldApplyStyles);
  }, [backgroundColor, location, isSudokuRoute, isMaxWidth812px, isLandscape]);
}

function RoutesContainer({ darkMode, setDarkMode }) {
  const location = useLocation();
  const rootBackgroundColor = darkMode ? "#111827" : "#fff";
  const otherRoutesBackgroundColor = "#fff";

  useUpdateBodyBackground(
    location.pathname === "/" ? rootBackgroundColor : otherRoutesBackgroundColor
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className="px-10 md:px-20 lg:px-40">
            <main>
              <MyHeader darkMode={darkMode} setDarkMode={setDarkMode} />
            </main>
            <Home darkMode={darkMode} />
          </div>
        }
      />
      <Route path="/wordle" element={<WordlePage className="bg-black" />} />
      <Route path="/pokemon" element={<PokemonPage className="bg-black" />} />
      <Route path="/sudoku" element={<SudokuPage className="bg-black" />} />
      <Route
        path="/realestate"
        element={<RealestatePage className="bg-black" />}
      />
      <Route path="/video/*" element={<VideoPage className="bg-black" />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            {/* <DashboardPage className="bg-black" /> */}

            {/* dummy dash placeholder*/}
            <div>
              <h1>Dummy Dashboard Page</h1>
            </div>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default function App() {
  const [darkMode, setDarkMode] = useState(true);

  const toggleDarkMode = useCallback(() => {
    setDarkMode((prevMode) => !prevMode);
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "F9") {
        toggleDarkMode();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [toggleDarkMode]);

  return (
    <BrowserRouter>
      <div className={darkMode ? "dark" : ""}>
        <RoutesContainer darkMode={darkMode} setDarkMode={setDarkMode} />
      </div>
    </BrowserRouter>
  );
}
