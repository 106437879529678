import React from "react";
import { BsFillMoonStarsFill } from "react-icons/bs";


function myHeader(props) {
  return (
    <>
      <nav className="py-10 flex justify-between dark:text-white ">
        <a href="/">
          <h1 className="font-burtons text-2xl  hover:bg-gray-600 hover:bg-opacity-20
                    dark:hover:bg-cyan-500 dark:hover:bg-opacity-30
                    round-corners animated-title">Wink Codes</h1>
        </a>
        <ul className="flex items-center">
          <li className="animated-icon">
            <BsFillMoonStarsFill
              onClick={() => {
                props.setDarkMode(!props.darkMode);
              }}
              className=" cursor-pointer text-2xl 
                    hover:bg-gray-600 hover:bg-opacity-20
                    dark:hover:bg-cyan-500 dark:hover:bg-opacity-30
                    round-corners
                    "
            />
          </li>
          <li className="animated-btn">
            <a
              className="bg-gradient-to-r from-cyan-500 text- to-teal-500 text-white px-4 py-2 border-none rounded-md ml-8 
                    hover:from-cyan-600 hover:to-teal-500 "
              href="https://my-aws-certs.s3.us-east-2.amazonaws.com/Andrew_Winkler_Resume.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Resumé
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default myHeader;
